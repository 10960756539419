import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { People as PeopleIcon, Delete as DeleteIcon } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import useStyles from './style';

interface ReportCardsProps {
  folders: FolderType[],
  handleFolderToDelete: (folder: FolderType) => void
  handleFolderCollaboratorsToEdit: (folder: FolderType) => void
}

const ReportCards = (props: ReportCardsProps) => {
  const classes = useStyles();

  return (
    <>
      {props.folders.map((f) => {
        return (
          <Paper key={f.id} square elevation={1} className={classes.paper}>
            <Link className={classes.stylessLink} to={`/folder-dashboards/${f.id}`}>
              <Typography variant="h6">
                {f.name}
                {' '}
                (
                {f.reports.length}
                )
              </Typography>
            </Link>
            <div className={classes.iconContainer}>
              {
                f.is_owner === 'true'
                  ? (
                    <Tooltip title="Team">
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => props.handleFolderCollaboratorsToEdit(f)}
                      >
                        <PeopleIcon />
                      </IconButton>
                    </Tooltip>
                  )
                  : null
              }
              <div className={classes.divisor} />
              {
                f.is_owner === 'true'
                  ? (
                    <Tooltip title="Delete">
                      <IconButton
                        className={classes.iconButton}
                        onClick={() => props.handleFolderToDelete(f)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )
                  : null
              }
            </div>
          </Paper>
        );
      })}
    </>
  );
};

export default ReportCards;
