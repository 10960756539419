/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import * as folderService from '../../entities/folder/service';
import * as reportService from '../../entities/report/service';
import Drawer from './Drawer';
import ScreenBar from './ScreenBar';
import Table from './Table';
import DeleteModal from '../../components/DeleteModal';
import UploadModal from '../../components/UploadModal';
import CustomDialog from '../../components/Navbar/CustomDialog';
import Loader from '../../components/Loader';
import useStyles from './style';
import { getSelectedFolderById, handleDownload } from '../../utils';

const FolderDashboard = () => {
  const classes = useStyles();
  const [folders, setFolders] = React.useState<FolderType[]>([]);
  const [documentTypes, setDocumentTypes] = React.useState<FootholdDocumentType[]>([]);
  const [fullReport, setFullReport] = React.useState<ReportType | null>(null);
  const [isDrawerOpen, setDrawerOpen] = React.useState(true);
  const [isDeleteCitationModalOpen, setDeleteCitationModalOpen] = React.useState(false);
  const [isDeleteSourceModalOpen, setDeleteSourceModalOpen] = React.useState(false);
  const [isSourceModalOpen, setSourceModalOpen] = React.useState(false);
  const [isReportModalOpen, setReportModalOpen] = React.useState(false);
  const [isUserGuideOpen, setUserGuideOpen] = React.useState(false);
  const [isNewReportModalOpen, setNewReportModalOpen] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState<number | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isHighlighting, setIsHighlighting] = React.useState<boolean>(false);

  const { id: folderId } = useParams();
  const currentFolder = getSelectedFolderById(folders, folderId);
  const folderName = _.get(currentFolder, 'name', '');
  const reports = _.get(currentFolder, 'reports', []);

  async function fetchFolders() {
    setIsLoading(true);
    const fetchedFolders = await folderService.getFolders();
    setFolders(fetchedFolders);
    setIsLoading(false);
  }

  async function fetchDocumentTypes() {
    setIsLoading(true);
    const fetchedDocumentTypes = await reportService.getDocumentTypes();
    setDocumentTypes(fetchedDocumentTypes);
    setIsLoading(false);
  }

  async function checkFolderVisit() {
    setIsLoading(true);
    const isFolderVisited = await folderService.hasFolderBeenVisited(folderId);
    if (!isFolderVisited) {
      setUserGuideOpen(!isUserGuideOpen);
    }
    setIsLoading(false);
  }

  React.useEffect(() => {
    fetchFolders();
    fetchDocumentTypes();
    checkFolderVisit();
  }, []);

  const handleDeleteCitationModal = () => {
    setDeleteCitationModalOpen(!isDeleteCitationModalOpen);
  };

  const handleCloseDeleteCitationModal = () => {
    setSelectedRowId(null);
    handleDeleteCitationModal();
  };

  const handleSetRowToDelete = (row: Citation) => {
    setSelectedRowId(row.citation_id);
    handleDeleteCitationModal();
  };

  const handleDeleteSourceModal = () => {
    setDeleteSourceModalOpen(!isDeleteSourceModalOpen);
  };

  const handleCloseDeleteSourceModal = () => {
    setSelectedRowId(null);
    handleDeleteSourceModal();
  };

  const handleSetSourceToDelete = (row: Citation) => {
    setSelectedRowId(row.citation_id);
    handleDeleteSourceModal();
  };

  const handleMaterialsDownload = () => {
    setIsHighlighting(true);
  };

  const handleSelectedReportId = async (reportId: number | null) => {
    if (!reportId) return;
    setIsLoading(true);
    const response = await reportService.getReportById(reportId);
    setFullReport(response);
    setIsLoading(false);
  };

  const onDeleteCitation = async () => {
    if (!selectedRowId) return;
    setIsLoading(true);
    const response = await reportService.deleteCitationById(selectedRowId);
    setIsLoading(false);
    handleCloseDeleteCitationModal();
    if (response) handleSelectedReportId(_.get(fullReport, 'id', null));
  };

  const onDeleteSource = async () => {
    if (!selectedRowId) return;
    setIsLoading(true);
    const response = await reportService.deleteSourceDocumentById(selectedRowId);
    setIsLoading(false);
    handleCloseDeleteSourceModal();
    if (response) handleSelectedReportId(_.get(fullReport, 'id', null));
  };

  const handleSetRawMaterials = async (row: Citation) => {
    setIsLoading(true);
    const newAreMaterialsIncluded = !_.get(row, 'are_materials_included');
    const data = {
      are_materials_included: _.capitalize(newAreMaterialsIncluded.toString()), // endpoint is expecting 'True' or 'False' as string not boolean true/false
    };
    await reportService.updateCitation(row.citation_id, data);
    setIsLoading(false);
    handleSelectedReportId(_.get(fullReport, 'id', null));
  };

  const handleSetDocumentType = async (citationId: number, newDocumentTypeId: number) => {
    setIsLoading(true);
    await reportService.updateCitation(
      citationId,
      { document_type_id: newDocumentTypeId },
    );
    setIsLoading(false);
    handleSelectedReportId(_.get(fullReport, 'id', null));
  };

  const handleSetFullCitationText = async (citationId: number, newValue: string) => {
    setIsLoading(true);
    await reportService.updateCitation(
      citationId,
      { full_text: newValue },
    );
    setIsLoading(false);
    handleSelectedReportId(_.get(fullReport, 'id', null));
  };

  const handleReportModalClose = () => {
    setReportModalOpen(!isReportModalOpen);
  };

  const handleNewReportModalClose = () => {
    setNewReportModalOpen(!isNewReportModalOpen);
  };

  const handleUserGuideClose = () => {
    setUserGuideOpen(!isUserGuideOpen);
  };

  const handleReportUploadFile = async (file:any, name?:string) => {
    setIsLoading(true);
    const response = await reportService.uploadReport(folderId, file, name || '');
    setIsLoading(false);
    handleReportModalClose();
    if (response) {
      fetchFolders();
      handleSelectedReportId(response.id);
    }
  };

  const handleNewReportUploadFile = async (file:any) => {
    setIsLoading(true);
    const response = await reportService.uploadNewReport(_.get(fullReport, 'id', null), file);
    setIsLoading(false);
    handleNewReportModalClose();
    if (response) handleSelectedReportId(_.get(fullReport, 'id', null));
  };

  const handleSourceModal = (row: Citation | null) => {
    setSelectedRowId(row && row.citation_id);
    setSourceModalOpen(!isSourceModalOpen);
  };

  const handleSourceDocumentUploadFile = async (file:any) => {
    setIsLoading(true);
    const response = await reportService.uploadSourceDocument(selectedRowId, file);
    handleSourceModal(null);
    setIsLoading(false);
    if (response) handleSelectedReportId(_.get(fullReport, 'id', null));
  };

  const handleDownloadSourceDocumentFile = async (row: Citation) => {
    setIsLoading(true);
    await handleDownload(
      `Document ${row.citation_source_filename_index}.${row.citation_source_filename_extension}`,
      reportService.getSourceDocumentFileByCitationId,
      row.citation_id,
    );
    setIsLoading(false);
  };

  const rows = _.uniqBy(_.get(fullReport, 'citations', []), 'citation_id');

  const selectedRow = _.find(rows, (row) => row.citation_id === selectedRowId);

  const modalDeleteCitationText = `Are you sure you want to delete the citation: '${_.get(selectedRow, 'raw_text', '')}'`;

  const modalDeleteSourceDocumentText = `Are you sure you want to delete the source document: '${_.get(selectedRow, 'citation_source_filename_index', '')}'`;

  const screenBarTitle = `${folderName} ${fullReport ? `- ${_.get(fullReport, 'name', '')}` : ''}`;

  return (
    <div className={classes.root}>
      <CustomDialog
        isDialogOpen={isUserGuideOpen}
        handleDialogClose={handleUserGuideClose}
      />
      <Loader
        isLoading={isLoading}
      />
      <UploadModal
        isModalOpen={isReportModalOpen}
        handleModalClose={handleReportModalClose}
        title="New Report Document"
        uploadFile={handleReportUploadFile}
      />
      <UploadModal
        isModalOpen={isNewReportModalOpen}
        handleModalClose={handleNewReportModalClose}
        title="New Report Document"
        uploadFile={handleNewReportUploadFile}
        shouldHideInput
      />
      <UploadModal
        isModalOpen={isSourceModalOpen}
        handleModalClose={() => setSourceModalOpen(false)}
        title="New Source Document"
        uploadFile={handleSourceDocumentUploadFile}
        shouldHideInput
      />
      <DeleteModal
        isModalOpen={isDeleteCitationModalOpen}
        handleModal={handleCloseDeleteCitationModal}
        modalInfoText={modalDeleteCitationText}
        onDelete={onDeleteCitation}
      />
      <DeleteModal
        isModalOpen={isDeleteSourceModalOpen}
        handleModal={handleCloseDeleteSourceModal}
        modalInfoText={modalDeleteSourceDocumentText}
        onDelete={onDeleteSource}
      />
      <Drawer
        open={isDrawerOpen}
        setOpen={setDrawerOpen}
        reports={reports}
        title={folderName}
        handleSelectedReportId={handleSelectedReportId}
        handleReportModalClose={handleReportModalClose}
        setIsLoading={setIsLoading}
      />
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: isDrawerOpen,
        })}
      >
        <ScreenBar
          title={screenBarTitle}
          report={fullReport}
          handleNewReportModalClose={handleNewReportModalClose}
          handleMaterialsDownload={handleMaterialsDownload}
          setIsLoading={setIsLoading}
        />
        <Table
          rows={rows}
          documentTypes={documentTypes}
          isHighlighting={isHighlighting}
          handleSetRowToDelete={handleSetRowToDelete}
          handleSetRawMaterials={handleSetRawMaterials}
          handleSetDocumentType={handleSetDocumentType}
          handleSetFullCitationText={handleSetFullCitationText}
          handleSourceModal={handleSourceModal}
          handleDownloadSourceDocumentFile={handleDownloadSourceDocumentFile}
          handleSetSourceToDelete={handleSetSourceToDelete}
        />
      </div>
    </div>
  );
};

export default FolderDashboard;
