import _ from 'lodash';

import Store from './Store';

const store = new Store();

export const getSelectedFolderById = (folders: FolderType[], selectedFolderId: number | null) => {
  return _.find(folders, (f) => f.id === Number(selectedFolderId));
};

const PERMISSIONS = ['foothold:admin', 'foothold:user'];

export const hasFootholdPermission = (user: UserType) => {
  const permission = _.head(_.get(user, 'https://keystonestrategy.io/permissions'));
  return _.includes(PERMISSIONS, permission);
};

export const isUserAdmin = (user: UserType) => {
  const permission = _.head(_.get(user, 'https://keystonestrategy.io/permissions'));
  return permission === PERMISSIONS[0];
};

export const getJwtApiToken = () => {
  return store.get('jwtToken');
};

export const setJwtApiToken = (token:string | null) => {
  return store.set('jwtToken', token);
};

export const clearStore = () => store.clear();

export const handleDownload = async (
  filename: string,
  service: (id: number) => Promise<any>,
  id:number,
) => {
  // 1. Convert data into 'blob'
  const response = await service(id);
  const blob = await response.blob();

  // 2. Create blob link to download
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  // 3. Append to html page
  document.body.appendChild(link);

  // 4. Force download
  link.click();

  // Clean up and remove the link
  if (!_.isNull(link.parentNode)) link.parentNode.removeChild(link);
};
