import React from 'react';
import {
  useStyles,
  StyledTabs,
  StyledTab,
} from './style';

import TabPanel from './TabPanel';
import Table from './table';
import Loader from '../../components/Loader';
import * as peopleService from '../../entities/people/service';

export default function AdminDashboard() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [toSignUpUsers, setToSignUpUsers] = React.useState<UserType[]>([]);
  const [users, setUsers] = React.useState<UserType[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchSignUpPeople = async () => {
    setIsLoading(true);
    try {
      const fetchedPeople = await peopleService.getSignUpPeople();
      setToSignUpUsers(fetchedPeople);
    } catch (error) {
      console.log('fetchSignUpPeople error', error);
    }
    setIsLoading(false);
  };

  const fetchPeople = async () => {
    setIsLoading(true);
    try {
      const fetchedPeople = await peopleService.getPeople();
      setUsers(fetchedPeople);
    } catch (error) {
      console.log('fetchPeople error', error);
    }
    setIsLoading(false);
  };

  const acceptSignUpUser = async (id: string) => {
    setIsLoading(true);
    const result = await peopleService.acceptSignUpUser(id);
    setIsLoading(false);
    if (result) fetchSignUpPeople();
  };

  const rejectSignUpUser = async (id: string) => {
    setIsLoading(true);
    const result = await peopleService.rejectSignUpUser(id);
    setIsLoading(false);
    if (result) fetchSignUpPeople();
  };

  const revokeUser = async (id: string) => {
    setIsLoading(true);
    const result = await peopleService.revokeUser(id);
    setIsLoading(false);
    if (result) fetchPeople();
  };

  React.useEffect(() => {
    fetchSignUpPeople();
    fetchPeople();
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.mainContainer}>
      <Loader
        isLoading={isLoading}
      />
      <div className={classes.container}>
        <StyledTabs
          selectionFollowsFocus
          variant="fullWidth"
          centered
          value={value}
          onChange={handleChange}
        >
          <StyledTab label="Permissions" />
          <StyledTab label="Manage Access" />
        </StyledTabs>
        <TabPanel value={value} index={0}>
          <Table
            rows={toSignUpUsers}
            actions={[
              {
                title: 'Accept',
                type: 'confirm',
                onClick: (id:string) => acceptSignUpUser(id),
              },
              {
                title: 'Reject',
                type: 'cancel',
                onClick: (id:string) => rejectSignUpUser(id),
              },
            ]}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Table
            rows={users}
            actions={[
              {
                title: 'Revoke',
                type: 'cancel',
                onClick: (id:string) => revokeUser(id),
              },
            ]}
          />
        </TabPanel>
      </div>
    </div>
  );
}
