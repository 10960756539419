import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useStyles from './style';
import { useAuth0 } from '../../react-auth0-spa';

const LoginPage = () => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h3" className={classes.title}>
        All your citations in one place!
      </Typography>
      <br />
      <br />
      <Button onClick={() => loginWithRedirect({})} className={classes.button}>
        Log in
      </Button>
    </div>
  );
};

export default LoginPage;
