import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  makeStyles, withStyles, Theme, createStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  container: {
    backgroundColor: theme.palette.background.paper,
  },
}));

interface StyledTabProps {
  label: string;
}

export const StyledTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
})(Tabs);

export const StyledTab = withStyles((theme: Theme) => createStyles({
  root: {
    textTransform: 'none',
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      color: 'white',
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
    },
  },
  selected: {}, // is needed this empty selected for the psedo-selector $selected to work
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props: StyledTabProps) => <Tab disableRipple {...props} />);
