/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './style';

interface LoaderProps {
  isLoading: boolean,
}

const Loader = (props: LoaderProps) => {
  const classes = useStyles();

  return (
    <Backdrop
      className={classes.backdrop}
      open={props.isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
