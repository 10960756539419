import _ from 'lodash';

import { axios } from '../../utils/httpRequests';

export async function getPeople():Promise<UserType[]> {
  const { data } = await axios.get('people');
  if (data.error) throw new Error(`${data.statusCode}: ${data.error}`);
  return data;
}

export async function revokeUser(id: string):Promise<boolean> {
  const { data } = await axios.delete(`people/${id}`);
  return _.get(data, '[1]') === 204;
}

export async function getSignUpPeople():Promise<UserType[]> {
  const { data } = await axios.get('people/signups');
  if (data.error) throw new Error(`${data.statusCode}: ${data.error}`);
  return data;
}

export async function rejectSignUpUser(id: string):Promise<boolean> {
  const { data } = await axios.delete(`people/signups/${id}`);
  return _.get(data, '[1]') === 204;
}

export async function acceptSignUpUser(id: string):Promise<UserType> {
  const { data } = await axios.post(`people/signups/${id}`, {
    personId: id,
  });
  return data;
}
