import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 35,
    outline: 0,
  },
  modalTitle: {
    color: theme.palette.success.main,
    textAlign: 'center',
  },
  greenButton: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    width: '40%',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  yellowButton: {
    color: 'white',
    backgroundColor: theme.palette.warning.main,
    width: '40%',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  uploadInputContainer: {
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.25)',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 10,
    minWidth: 397,
  },
  uploadInputTextStyle: {
    fontFamily: 'inherit',
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 0.25)',
  },
  titleInput: {
    width: '100%',
  },
  divisor: {
    margin: theme.spacing(2),
  },
  textFileStyle: {
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));
