import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  greenButton: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  yellowButton: {
    color: 'white',
    backgroundColor: theme.palette.warning.main,
  },
  buttonContainer: {
    display: 'flex',
  },
  divisor: {
    margin: theme.spacing(1),
  },
}));
