/* eslint-disable react/button-has-type */
import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import HomeIcon from '@material-ui/icons/Home';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import UserIcon from './UserIcon';
import { useAuth0 } from '../../react-auth0-spa';
import { hasFootholdPermission } from '../../utils';
import useStyles from './style';

const Navbar = () => {
  const classes = useStyles();
  const {
    isAuthenticated, logout, user,
  } = useAuth0();

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Link className={classes.stylessLink} to="/">
              <HomeIcon />
              &nbsp;&nbsp;
              Foothold
            </Link>
          </Typography>
          {isAuthenticated && hasFootholdPermission(user)
            ? <UserIcon logout={logout} user={user} />
            : null}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
