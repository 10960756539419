/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import _ from 'lodash';

import { getReportFileById, getReportMaterialsConsidered } from '../../entities/report/service';
import { handleDownload } from '../../utils';
import useStyles from './style';

interface ScreenBarProps {
  title: string,
  report: ReportType | null,
  handleNewReportModalClose: () => void,
  handleMaterialsDownload: () => void,
  setIsLoading: (value: true | false) => void,
}

const ScreenBar = (props: ScreenBarProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownloadReportFile = async () => {
    handleClose();
    if (_.isNull(props.report)) return;
    props.setIsLoading(true);
    handleDownload(`${_.get(props.report, 'name', 'report')}.docx`, getReportFileById, props.report.id);
    props.setIsLoading(false);
  };

  const handleDownloadMaterialsConsidered = async () => {
    handleClose();
    props.handleMaterialsDownload();
    if (_.isNull(props.report)) return;
    props.setIsLoading(true);
    handleDownload('source.zip', getReportMaterialsConsidered, props.report.id);
    props.setIsLoading(false);
  };

  return (
    <AppBar className={classes.screenBar} position="static">
      <Toolbar className={classes.toolbar}>
        <div />
        <Typography className={classes.toolbarText} variant="h6">
          {props.title}
        </Typography>
        <IconButton
          aria-label="Menu"
          aria-controls="menu-screen"
          aria-haspopup="true"
          onClick={handleMenu}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="menu-screen"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disabled={!props.report} onClick={handleDownloadReportFile}>
            Download latest document
          </MenuItem>
          <MenuItem
            disabled={!props.report}
            onClick={() => {
              props.handleNewReportModalClose();
              handleClose();
            }}
          >
            Upload new report document
          </MenuItem>
          <MenuItem
            disabled={!props.report}
            onClick={handleDownloadMaterialsConsidered}
          >
            Get materials considered
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default ScreenBar;
