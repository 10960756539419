/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Typography from '@material-ui/core/Typography';

import useStyles from './style';

interface DrawerListProps {
  reports: any,
  handleSelectedReportId: (reportId: number) => void,
  setIsLoading: (value: true | false) => void,
}

const DrawerList = (props: DrawerListProps) => {
  const classes = useStyles();

  return (
    <>
      {
        props.reports.map((report: any) => (
          <div
            key={report.id}
            className={classes.reportContainer}
            onClick={async () => {
              props.setIsLoading(true);
              await props.handleSelectedReportId(report.id);
              props.setIsLoading(false);
            }}
          >
            <Typography className={classes.reportText}>{report.name}</Typography>
          </div>
        ))
      }
    </>
  );
};

export default DrawerList;
