/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import ModalWrapper from '../ModalWrapper';
import useStyles from './style';

interface DeleteModalProps {
  isModalOpen: boolean,
  handleModal: () => void,
  modalInfoText: string,
  onDelete: () => void,
}

const DeleteModal = (props: DeleteModalProps) => {
  const classes = useStyles();
  return (
    <ModalWrapper isModalOpen={props.isModalOpen} handleModal={props.handleModal}>
      <Typography>
        {props.modalInfoText}
      </Typography>
      <div className={classes.divisor} />
      <div className={classes.buttonsContainer}>
        <Button onClick={props.handleModal} className={classes.yellowButton}>Cancel</Button>
        <Button onClick={props.onDelete} className={classes.greenButton}>Confirm</Button>
      </div>
    </ModalWrapper>
  );
};

export default DeleteModal;
