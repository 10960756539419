/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { createFolder } from '../../../entities/folder/service';
import useStyles from './style';

interface NewFolderModalProps {
  isModalOpen: boolean,
  handleModal: () => void,
  updateFolders: () => void,
  setIsLoading: (value: true | false) => void,
}

const NewFolderModal = (props: NewFolderModalProps) => {
  const [folderNameInput, setFolderNameInput] = React.useState('');
  const classes = useStyles();

  const onChangeFolderName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolderNameInput(e.target.value);
  };

  const handleModal = () => {
    setFolderNameInput('');
    props.handleModal();
  };

  const onSubmit = async () => {
    props.setIsLoading(true);
    const newFolder = await createFolder(folderNameInput);
    props.setIsLoading(false);
    handleModal();
    if (newFolder) props.updateFolders();
  };

  return (
    <Modal
      className={classes.modal}
      open={props.isModalOpen}
      onClose={handleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isModalOpen}>
        <div className={classes.paper}>
          <Typography variant="h6" className={classes.modalTitle}>
            New folder
          </Typography>
          <div className={classes.divisor} />
          <TextField
            placeholder="Folder name"
            variant="outlined"
            className={classes.titleInput}
            onChange={onChangeFolderName}
            value={folderNameInput}

          />
          {/* <div className={classes.divisor} />
          <TextField
            placeholder="Add team members"
            variant="outlined"
            className={classes.titleInput}
          /> */}
          <div className={classes.divisor} />
          <div className={classes.buttonsContainer}>
            <Button onClick={handleModal} className={classes.yellowButton}>Cancel</Button>
            <Button
              disabled={!folderNameInput.trim()}
              onClick={onSubmit}
              className={classes.greenButton}
            >
              Create
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default NewFolderModal;
