import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import useStyles from './style';

interface Action {
  title: string,
  type: string,
  onClick: (id:string) => void,
}

interface AdminTableProps {
  rows: UserType[],
  actions: Action[]
}

export default function AdminTable(props: AdminTableProps) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row: UserType) => {
            const email = row.email ? row.email.toLowerCase() : '<Email Not Found>';
            return ((
              <TableRow key={row.user_id}>
                <TableCell>
                  {row.name}
                </TableCell>
                <TableCell>{email}</TableCell>
                <TableCell className={classes.buttonContainer}>
                  {props.actions.map((action: Action) => {
                    return (
                      <div className={classes.buttonContainer} key={action.title}>
                        <Button
                          variant="contained"
                          className={action.type === 'confirm'
                            ? classes.greenButton
                            : classes.yellowButton}
                          onClick={() => action.onClick(row.user_id)}
                        >
                          {action.title}
                        </Button>
                        <div className={classes.divisor} />
                      </div>
                    );
                  })}
                </TableCell>
              </TableRow>
            ));
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
