/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import DrawerHeader from './DrawerHeader';
import DrawerList from './DrawerList';
import useStyles from './style';

interface CustomDrawerProps {
  reports: any,
  title: string,
  open: boolean,
  setOpen: (open: boolean) => void,
  handleSelectedReportId: (reportId: number) => void,
  handleReportModalClose: () => void,
  setIsLoading: (value: true | false) => void,
}

const CustomDrawer = (props: CustomDrawerProps) => {
  const classes = useStyles();

  const handleDrawerOpen = () => {
    props.setOpen(true);
  };

  const handleDrawerClose = () => {
    props.setOpen(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={props.open}
      classes={{ paper: classes.drawerPaper }}
    >
      <DrawerHeader
        title={props.title}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      <Divider />
      <Button
        variant="contained"
        className={classes.greenButton}
        onClick={props.handleReportModalClose}
      >
        New Report
      </Button>
      <DrawerList
        setIsLoading={props.setIsLoading}
        handleSelectedReportId={props.handleSelectedReportId}
        reports={props.reports}
      />
    </Drawer>
  );
};

export default CustomDrawer;
