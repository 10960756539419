import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export default makeStyles((theme: Theme) => createStyles({
  screenBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  toolbar: {
    display: 'flex',
    paddingRight: 0,
    justifyContent: 'space-between',
  },
  toolbarText: {
    color: 'black',
  },
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    padding: '0 20px',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
