import React from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';
import { getNameInitials } from './utils';
import { isUserAdmin } from '../../utils';
import CustomDialog from './CustomDialog';

interface UserIconProps {
  logout: (returnTo: Object) => void,
  user: UserType,
}

const UserIcon = (props: UserIconProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isUserGuideOpen, setUserGuideOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserGuideOpen = () => {
    setUserGuideOpen(!isUserGuideOpen);
  };

  const handleUserGuideClose = () => {
    setUserGuideOpen(!isUserGuideOpen);
  };

  const nameInitials = getNameInitials(props.user);

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar className={classes.avatar}>
          {nameInitials ? <Typography variant="h6">{nameInitials}</Typography> : <AccountCircle fontSize="large" />}
        </Avatar>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          handleClose();
          props.logout({ returnTo: window.location.origin });
        }}
        >
          Log out
        </MenuItem>
        {
          isUserAdmin(props.user)
            ? (
              <MenuItem
                onClick={() => handleClose()}
                component={Link}
                to="/admin-dashboards"
              >
                Admin
              </MenuItem>
            )
            : null
        }
        <MenuItem onClick={() => {
          if (open) {
            handleClose();
            handleUserGuideOpen();
          }
        }}
        >
          User Guide
          <CustomDialog
            isDialogOpen={isUserGuideOpen}
            handleDialogClose={handleUserGuideClose}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserIcon;
