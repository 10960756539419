import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.info.dark,
    boxShadow: 'none',
  },
  avatar: {
    backgroundColor: 'white',
    color: theme.palette.info.dark,
  },
  stylessLink: {
    color: 'inherit',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
}));
