import { API_URL } from '../../config';
import { axios } from '../../utils/httpRequests';
import { getJwtApiToken } from '../../utils';

export async function getReportById(reportId: number):Promise<ReportType> {
  const { data } = await axios.get(`reports/${reportId}`);
  return data;
}

export async function fetchWithToken(uri:string):Promise<any> {
  const token = getJwtApiToken();
  return fetch(uri, {
    method: 'get',
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  });
}

export async function getReportFileById(reportId: number):Promise<any> {
  return fetchWithToken(`${API_URL}reports/${reportId}/file`);
}

export async function getReportMaterialsConsidered(reportId: number):Promise<any> {
  return fetchWithToken(`${API_URL}reports/${reportId}/materials-considered`);
}

export async function getSourceDocumentFileByCitationId(citationId: number):Promise<any> {
  return fetchWithToken(`${API_URL}citations/${citationId}/source`);
}

export async function deleteCitationById(id:number):Promise<Boolean> {
  const { status } = await axios.delete(`${API_URL}citations/${id}`);
  return status === 204;
}

export async function deleteSourceDocumentById(id:number):Promise<Boolean> {
  const { status } = await axios.delete(`${API_URL}citations/${id}/source`);
  return status === 204;
}

export async function uploadReport(folderId:number, file: any, name:string):Promise<ReportType> {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('name', name);
  const { data } = await axios.post(`folders/${folderId}/reports`, formData);
  return data;
}

export async function uploadNewReport(reportId:number | null, file: any):Promise<ReportType> {
  const formData = new FormData();
  formData.append('file', file, file.name);
  const { data } = await axios.post(`reports/${reportId}/new-version`, formData);
  return data;
}

export async function uploadSourceDocument(citationId:number | null, file: any):Promise<Citation> {
  const formData = new FormData();
  formData.append('file', file, file.name);
  const { data } = await axios.patch(`citations/${citationId}/source`, formData);
  return data;
}

export async function updateCitation(citationId:number, updatedData:any) {
  const { data } = await axios.patch(`citations/${citationId}`, updatedData);
  return data;
}

export async function getDocumentTypes():Promise<FootholdDocumentType[]> {
  const { data } = await axios.get('document-types');
  return data;
}
