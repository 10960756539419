import _ from 'lodash';

const getPersonFullName = (person: UserType) => _.get(person, 'name', '');

const getInitial = (str: string) => _.upperCase(str.charAt(0));

export const getNameInitials = (person: UserType) => {
  const name = getPersonFullName(person);
  const splitedName = _.split(name, ' ', 2);
  return _.join(_.map(splitedName, getInitial), '');
};
