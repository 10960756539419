/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import { Clear as ClearIcon } from '@material-ui/icons';

import useStyles from './style';

interface UploadButtonProps {
  handleSourceModal: (row:Citation) => void,
  handleSetSourceToDelete: (row:Citation) => void,
  handleDownloadSourceDocumentFile: (row: Citation) => void,
  row: any,
}

const UploadButton = (props: UploadButtonProps) => {
  const classes = useStyles();
  const sourceDocumentIndex = _.get(props, 'row.citation_source_filename_index');
  const sourceDocumentExtension = _.get(props, 'row.citation_source_filename_extension');
  return (
    <div>
      { sourceDocumentIndex && sourceDocumentExtension
        ? (
          <div className={classes.sourceDocumentContainer}>
            <IconButton
              onClick={() => props.handleSetSourceToDelete(props.row)}
              className={classes.iconButton}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
            <div
              onClick={() => props.handleDownloadSourceDocumentFile(props.row)}
              className={classes.textFileButton}
              role="button"
            >
              {`Document ${sourceDocumentIndex}.${sourceDocumentExtension}`}
            </div>
          </div>
        )
        : (
          <Button
            onClick={() => props.handleSourceModal(props.row)}
            size="small"
            variant="contained"
            color="primary"
          >
            Choose&nbsp;File
          </Button>
        )}
    </div>
  );
};

export default UploadButton;
