import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useStyle from './style';
import { useAuth0 } from '../../react-auth0-spa';

const NoAuthorized = () => {
  const classes = useStyle();
  const { logout } = useAuth0();

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h4" className={classes.title}>
        You currently have no access to Citations Manager
      </Typography>
      <Typography variant="h4" className={classes.title}>
        Your request has been submitted.
      </Typography>
      <br />
      <br />
      <Typography variant="h4" className={classes.title}>
        Soon you will receive a confirmation email from an
      </Typography>
      <Typography variant="h4" className={classes.title}>
        admin granting access to your projects!
      </Typography>
      <br />
      <br />
      <Button onClick={logout} className={classes.button}>
        Back to login page
      </Button>
    </div>
  );
};

export default NoAuthorized;
