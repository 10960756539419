/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';

import ModalWrapper from '../../../components/ModalWrapper';
import { getSelectedFolderById } from '../../../utils';
import { addCollaboratorToFolder, removeCollaboratorFromFolder } from '../../../entities/folder/service';
import useStyles from './style';

interface EditCollaboratorsModalProps {
  isModalOpen: boolean,
  handleModal: () => void,
  selectedFolderId: number | null,
  folders: FolderType[],
  updateFolders: () => {},
  users: UserType[],
  setIsLoading: (value: true | false) => void
}

const EditCollaboratorsModal = (props: EditCollaboratorsModalProps) => {
  const [user, setUser] = React.useState<UserType | null>(null);
  const classes = useStyles();

  const handleModal = () => {
    setUser(null);
    props.handleModal();
  };

  const selectedFolder = getSelectedFolderById(props.folders, props.selectedFolderId);

  const onSubmit = async () => {
    props.setIsLoading(true);
    const response = await addCollaboratorToFolder(props.selectedFolderId, _.get(user, 'user_id', ''));
    props.setIsLoading(false);
    if (response !== 'Succesfully added collaborator.') return;
    setUser(null);
    props.updateFolders();
  };

  const onRemove = async (id: string) => {
    const response = await removeCollaboratorFromFolder(props.selectedFolderId, id);
    if (response === 'Succesfully removed collaborator.') props.updateFolders();
  };

  const collaborators = _.get(selectedFolder, 'collaborators', []);

  return (
    <ModalWrapper isModalOpen={props.isModalOpen} handleModal={handleModal}>
      <Typography variant="h6" className={classes.modalTitle}>
        {_.get(selectedFolder, 'name', null)}
      </Typography>
      <Typography variant="h6" className={classes.modalTitle}>
        Team(
        {collaborators.length}
        )
      </Typography>
      <div className={classes.divisor} />
      <Autocomplete
        id="combo-box-demo"
        options={props.users}
        value={user}
        getOptionLabel={(option:any) => option.name}
        onChange={(event: any, newValue: UserType | null) => {
          setUser(newValue);
        }}
        renderInput={
          (params:any) => (
            <TextField
              {...params}
              className={classes.titleInput}
              label="Add team member"
              variant="outlined"
            />
          )
        }
      />
      <div className={classes.divisor} />
      {
        collaborators.length > 0
          ? (
            <Paper variant="outlined" className={classes.paper}>
              {_.map(collaborators, (c: CollaboratorType) => (
                <Chip
                  key={c.user_id}
                  label={c.name}
                  className={classes.chip}
                  onDelete={() => onRemove(c.user_id)}
                />
              ))}
            </Paper>
          )
          : null
      }
      <div className={classes.divisor} />
      <div className={classes.buttonsContainer}>
        <Button
          onClick={handleModal}
          className={classes.yellowButton}
        >
          Cancel
        </Button>
        <Button
          disabled={!user}
          onClick={onSubmit}
          className={classes.greenButton}
        >
          Update
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default EditCollaboratorsModal;
