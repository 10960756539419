class Store {
  store: any;

  prefix: any;

  constructor(prefix = 'global', store = window.localStorage) {
    this.store = store;
    this.prefix = prefix;
  }

  set(key: string, value:any) {
    this.store.setItem(`${this.prefix}/${key}`, JSON.stringify(value));
  }

  get(key:string) {
    return JSON.parse(this.store.getItem(`${this.prefix}/${key}`));
  }

  clear() {
    this.store.clear();
  }
}

export default Store;
