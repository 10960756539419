import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import _ from 'lodash';

import * as folderService from '../../entities/folder/service';
import * as peopleService from '../../entities/people/service';
import { getSelectedFolderById } from '../../utils';
import useStyles from './style';
import ReportCards from './ReportCards';
import NewFolderModal from './NewFolderModal';
import DeleteFolderModal from '../../components/DeleteModal';
import EditCollaboratorsModal from './EditCollaboratorsModal';
import Loader from '../../components/Loader';

const HomePage = () => {
  const [folders, setFolders] = React.useState<FolderType[]>([]);
  const [users, setUsers] = React.useState<UserType[]>([]);
  const [isNewFolderModalOpen, setNewFolderModalOpen] = React.useState(false);
  const [isDeleteFolderModalOpen, setDeleteFolderModalOpen] = React.useState(false);
  const [isEditCollaboratorsModalOpen, setEditCollaboratorsModalOpen] = React.useState(false);
  const [selectedFolderId, setSelectedFolderId] = React.useState<number | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const classes = useStyles();

  const fetchFolders = async () => {
    setIsLoading(true);
    const fetchedFolders = await folderService.getFolders();
    setFolders(fetchedFolders);
    setIsLoading(false);
  };

  const fetchPeople = async () => {
    setIsLoading(true);
    try {
      const fetchedPeople = await peopleService.getPeople();
      setUsers(fetchedPeople);
    } catch (error) {
      console.log('fetchPeople error', error);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchFolders();
    fetchPeople();
  }, []);

  const handleNewFolderModal = () => {
    setNewFolderModalOpen(!isNewFolderModalOpen);
  };

  const handleDeleteFolderModal = () => {
    setDeleteFolderModalOpen(!isDeleteFolderModalOpen);
  };

  const handleEditCollaboratorsModal = () => {
    setEditCollaboratorsModalOpen(!isEditCollaboratorsModalOpen);
  };

  const handleFolderToDelete = (folder:FolderType) => {
    setSelectedFolderId(folder.id);
    handleDeleteFolderModal();
  };

  const handleFolderCollaboratorsToEdit = (folder:FolderType) => {
    setSelectedFolderId(folder.id);
    handleEditCollaboratorsModal();
  };

  const handleCloseDeleteModal = () => {
    handleDeleteFolderModal();
    setSelectedFolderId(null);
  };

  const onDelete = async () => {
    setIsLoading(true);
    const response = await folderService.deleteFolderById(selectedFolderId);
    setIsLoading(false);
    handleCloseDeleteModal();
    if (response) fetchFolders();
  };

  const selectedFolder = getSelectedFolderById(folders, selectedFolderId);
  // eslint-disable-next-line max-len
  const modalInfoText = `Are you sure you want to delete the folder '${_.get(selectedFolder, 'name', '')}' with its ${_.get(selectedFolder, 'reports.length', '')} reports`;

  const myFolders = folders.filter((f: FolderType) => f.is_owner === 'true');
  const sharedFolders = folders.filter((f: FolderType) => f.is_owner === 'false');
  return (
    <div className={classes.mainContainer}>
      <Loader
        isLoading={isLoading}
      />
      <NewFolderModal
        isModalOpen={isNewFolderModalOpen}
        handleModal={handleNewFolderModal}
        updateFolders={fetchFolders}
        setIsLoading={setIsLoading}
      />
      <DeleteFolderModal
        isModalOpen={isDeleteFolderModalOpen}
        handleModal={handleCloseDeleteModal}
        modalInfoText={modalInfoText}
        onDelete={onDelete}
      />
      <EditCollaboratorsModal
        isModalOpen={isEditCollaboratorsModalOpen}
        handleModal={handleEditCollaboratorsModal}
        folders={folders}
        selectedFolderId={selectedFolderId}
        updateFolders={fetchFolders}
        users={users}
        setIsLoading={setIsLoading}
      />
      <div className={classes.foldersContainer}>
        <Typography className={classes.title} variant="h5" gutterBottom>
          My folders (
          {myFolders.length}
          )
        </Typography>
        <div>
          <Button onClick={handleNewFolderModal} variant="contained" className={classes.button}>
            <Typography variant="h6">New</Typography>
          </Button>
        </div>
        <ReportCards
          handleFolderToDelete={handleFolderToDelete}
          handleFolderCollaboratorsToEdit={handleFolderCollaboratorsToEdit}
          folders={myFolders}
        />
      </div>
      <div className={classes.foldersContainer}>
        <Typography className={classes.title} variant="h5" gutterBottom>
          Folders shared with me (
          {sharedFolders.length}
          )
        </Typography>
        <div className={classes.invisibleButton}>
          <Button variant="contained" className={classes.button}>
            <Typography variant="h6">New</Typography>
          </Button>
        </div>
        <ReportCards
          handleFolderToDelete={handleFolderToDelete}
          handleFolderCollaboratorsToEdit={handleFolderCollaboratorsToEdit}
          folders={sharedFolders}
        />
      </div>
    </div>
  );
};

export default HomePage;
