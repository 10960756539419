import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

import { setJwtApiToken, clearStore } from './utils';
import { axios } from './utils/httpRequests';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<any>({});
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}:any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [auth0Client, setAuth0] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      setLoading(true);
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes('code=')
          && window.location.search.includes('state=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const auth0IsAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(auth0IsAuthenticated);

      if (auth0IsAuthenticated) {
        const auth0User = await auth0FromHook.getUser();
        const token = await auth0FromHook.getTokenSilently();
        setJwtApiToken(token);
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        setUser(auth0User);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      if (!auth0Client) return;
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const auth0User = await auth0Client.getUser();
    setUser(auth0User);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const auth0User = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(auth0User);
  };
  const value:any = {
    isAuthenticated,
    user,
    loading,
    popupOpen,
    loginWithPopup,
    handleRedirectCallback,
    getIdTokenClaims: (...p:any) => auth0Client.getIdTokenClaims(...p),
    loginWithRedirect: (...p:any) => auth0Client.loginWithRedirect({ ...p, connection_scope: 'offline_access' }),
    getTokenSilently: (...p:any) => auth0Client.getTokenSilently(...p),
    logout: (...p:any) => {
      clearStore();
      auth0Client.logout(...p);
    },
  };
  return (
    <Auth0Context.Provider
      value={value}
    >
      {children}
    </Auth0Context.Provider>
  );
};
