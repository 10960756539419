import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

interface DialogProps{
  isDialogOpen: boolean,
  handleDialogClose: () => void
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const CustomDialog = (props: DialogProps) => {
  return (
    <div>
      <Dialog onClose={props.handleDialogClose} aria-labelledby="customized-dialog-title" open={props.isDialogOpen}>
        <DialogTitle id="customized-dialog-title">
          User Guide
        </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Welcome to Foothold!
              </Typography>
              <Typography gutterBottom>
                Before you create a new report, make sure your document has the following format:
              </Typography>
              <Typography gutterBottom>
                &nbsp;1.&nbsp;&nbsp;&nbsp;
                <b>Add 3 curly brackets</b>
                {' '}
                to begin and to end your raw citation
                {' '}
                <b>&#123;&#123;&#123;....&#125;&#125;&#125;</b>
              </Typography>
              <Typography gutterBottom>
                &nbsp;2.&nbsp;&nbsp;&nbsp;
                <b>Exclude page numbers and context information</b>
                {' '}
                from the brackets.
              </Typography>
              <Typography gutterBottom>
                <b>Note:</b>
                If you
                <b> do not add the brackets</b>
                {' '}
                to your citations,
                <b> Foothold will not display them in the folder dashboard!</b>
              </Typography>
            </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleDialogClose} color="primary">
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
