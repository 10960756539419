/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
// import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import _ from 'lodash';

import UploadButton from './UploadButton';
import Dropdown from './Dropdown';
import useStyles from './style';

interface CustomTableBodyProps {
  rows: Citation[],
  documentTypes: FootholdDocumentType[],
  isHighlighting: boolean,
  handleSetRowToDelete: (row: Citation) => void,
  handleSetRawMaterials: (row: Citation) => void,
  handleSetDocumentType: (citationId: number, newDocumentTypeId: number) => void,
  handleSetFullCitationText: (citationId: number, newValue: string) => void,
  handleSourceModal: (row: Citation) => void,
  handleDownloadSourceDocumentFile: (row: Citation) => void,
  handleSetSourceToDelete: (row: Citation) => void,
}

const CustomTableBody = (props: CustomTableBodyProps) => {
  const classes = useStyles();
  const sortedRows = _.sortBy(props.rows, ['id']);
  return (
    <TableBody>
      {sortedRows.map((row: Citation) => {
        return (
          <TableRow key={row.citation_id}>
            <TableCell style={{ width: '10%' }} className={classes.cell} align="center" scope="row">
              <Checkbox
                checked={row.are_materials_included}
                onChange={() => props.handleSetRawMaterials(row)}
                color="primary"
              />
              {/* <Button
                onClick={() => props.handleSetRowToDelete(row)}
                size="small"
                variant="contained"
                color="secondary"
              >
                Delete
              </Button> */}
            </TableCell>
            <TableCell style={{ width: '30%' }} className={classes.cell} align="right">
              <InputBase
                id="outlined-multiline-static"
                multiline
                rows={4}
                disabled
                defaultValue={row.raw_text || ''}
                className={classes.textFieldStyle}
              />
            </TableCell>
            <TableCell
              style={(!row.full_text && row.are_materials_included && props.isHighlighting) ? {
                width: '30%',
                boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                border: '3px solid rgba(30,144,255, 1)',
              } : { width: '30%' }}
              className={classes.cell}
              align="right"
            >
              <InputBase
                id="outlined-multiline-static"
                multiline
                rows={4}
                defaultValue={row.full_text || ''}
                className={classes.textFieldStyle}
                onBlur={(event) => {
                  if (!event) return;
                  const value = _.get(event, 'target.value', null);
                  const oldValue = _.get(row, 'full_text', null);

                  if (value === oldValue) return;
                  props.handleSetFullCitationText(row.citation_id, event.target.value);
                }}
              />
            </TableCell>
            <TableCell style={{ width: '20%' }} className={classes.cell} align="left">
              <Dropdown
                options={props.documentTypes}
                handleSetDocumentType={props.handleSetDocumentType}
                value={row.document_type_id || 4}
                row={row}
              />
            </TableCell>
            <TableCell style={{ width: '10%' }} className={classes.cell} align="center">
              <UploadButton
                handleDownloadSourceDocumentFile={props.handleDownloadSourceDocumentFile}
                handleSourceModal={props.handleSourceModal}
                handleSetSourceToDelete={props.handleSetSourceToDelete}
                row={row}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default CustomTableBody;
