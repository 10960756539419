import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  cell: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  headerCell: {
    backgroundColor: theme.palette.primary.main,
    border: '1px solid rgba(224, 224, 224, 1)',
    color: 'white',
  },
  textFileButton: {
    color: '-webkit-link',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  iconButton: {
    color: '-webkit-link',
    padding: 0,
  },
  sourceDocumentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldStyle: {
    display: 'flex',
    width: '100%',
    border: '0px',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.43,
    color: 'inherit !important',
  },
}));
