/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import TableBody from './TableBody';
import useStyles from './style';

interface CustomTableProps {
  rows: Citation[],
  documentTypes: FootholdDocumentType[],
  isHighlighting: boolean;
  handleSetRowToDelete: (row: Citation) => void,
  handleSetRawMaterials: (row: Citation) => void,
  handleSetDocumentType: (citationId: number, newDocumentTypeId: number) => void,
  handleSetFullCitationText: (citationId: number, newValue: string) => void,
  handleSourceModal: (row: Citation) => void,
  handleDownloadSourceDocumentFile: (row: Citation) => void,
  handleSetSourceToDelete: (row: Citation) => void,
}

const CustomTable = (props: CustomTableProps) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: '10%' }} className={classes.headerCell} align="center">Materials considered</TableCell>
            <TableCell style={{ width: '30%' }} className={classes.headerCell} align="center">Raw Citation</TableCell>
            <TableCell style={{ width: '30%' }} className={classes.headerCell} align="center">Full Citation</TableCell>
            <TableCell style={{ width: '20%' }} className={classes.headerCell} align="center">Document Type</TableCell>
            <TableCell style={{ width: '10%' }} className={classes.headerCell} align="center">Source Document</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          rows={props.rows}
          documentTypes={props.documentTypes}
          isHighlighting={props.isHighlighting}
          handleSetRowToDelete={props.handleSetRowToDelete}
          handleSetRawMaterials={props.handleSetRawMaterials}
          handleSetDocumentType={props.handleSetDocumentType}
          handleSetFullCitationText={props.handleSetFullCitationText}
          handleSourceModal={props.handleSourceModal}
          handleDownloadSourceDocumentFile={props.handleDownloadSourceDocumentFile}
          handleSetSourceToDelete={props.handleSetSourceToDelete}
        />
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
