/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';

import useStyles from './style';

interface UploadModalProps {
  isModalOpen: boolean,
  title: string,
  handleModalClose: () => void,
  uploadFile: (file:any, name?:string) => void,
  shouldHideInput?: boolean,
}

const UploadModal = (props: UploadModalProps) => {
  const classes = useStyles();
  const [fileName, setFileName] = React.useState('');
  const [file, setFile] = React.useState<any>(null);
  const shouldShowInput = !props.shouldHideInput;

  const onDrop = useCallback((acceptedFiles:any) => {
    const acceptedFile:any = _.head(acceptedFiles);
    if (!acceptedFile) return;
    setFile(acceptedFile);
    if (fileName !== '') return;
    const fileNameToSet = (acceptedFile.name || '').split(['.'])[0];
    setFileName(fileNameToSet);
  }, [fileName]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  });

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(e.target.value);
  };

  const onClose = () => {
    props.handleModalClose();
    setFileName('');
    setFile(null);
  };

  return (
    <Modal
      className={classes.modal}
      open={props.isModalOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isModalOpen}>
        <div className={classes.paper}>
          <Typography variant="h6" className={classes.modalTitle}>
            {props.title}
          </Typography>
          <div className={classes.divisor} />
          {
            shouldShowInput
              ? (
                <TextField
                  InputProps={{
                    startAdornment: <InputAdornment position="start">Title:</InputAdornment>,
                  }}
                  value={fileName}
                  variant="outlined"
                  onChange={onChangeName}
                  className={classes.titleInput}
                />
              )
              : null
          }
          <div className={classes.divisor} />
          <div
            {...getRootProps({ className: 'dropzone' })}
            className={classes.uploadInputContainer}
          >
            <input {...getInputProps()} />
            {
              file
                ? (
                  <Typography className={classes.textFileStyle} key={file.path}>
                    {file.path}
                  </Typography>
                )
                : (
                  <Typography className={classes.uploadInputTextStyle}>
                    Drag and drop your document, or click to select files
                  </Typography>
                )
            }
          </div>
          <div className={classes.divisor} />
          <div className={classes.buttonsContainer}>
            <Button onClick={onClose} className={classes.yellowButton}>Cancel</Button>
            <Button
              onClick={() => {
                props.uploadFile(file, fileName);
                onClose();
              }}
              className={classes.greenButton}
              disabled={!file || (shouldShowInput && !fileName.trim())}
            >
              Upload
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default UploadModal;
