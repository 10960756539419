import { makeStyles, Theme } from '@material-ui/core/styles';

const buttonStyle = {
  width: '40%',
  color: 'white',
  maxWidth: 150,
};

export default makeStyles((theme: Theme) => ({
  greenButton: {
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
    ...buttonStyle,
  },
  yellowButton: {
    backgroundColor: theme.palette.warning.main,
    ...buttonStyle,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  divisor: {
    margin: theme.spacing(2),
  },
}));
