import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  mainContainer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingTop: '10%',
    flexWrap: 'wrap',
  },
  foldersContainer: {
    maxWidth: '700px',
    minWidth: '200px',
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    color: 'white',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: theme.palette.success.main,
    margin: theme.spacing(1),
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  invisibleButton: {
    visibility: 'hidden',
  },
  iconContainer: {
    display: 'flex',
  },
  divisor: {
    margin: theme.spacing(0.5),
  },
  iconButton: {
    color: 'white',
    padding: 0,
    backgroundColor: theme.palette.primary.main,
  },
  stylessLink: {
    color: 'white',
    textDecoration: 'none',
  },
}));
