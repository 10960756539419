import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useAuth0 } from '../../react-auth0-spa';
import { hasFootholdPermission } from '../../utils';
import HomePage from '../../views/home';
import LoginPage from '../../views/login';
import FolderDashboard from '../../views/folderDashboard';
import AdminDashboard from '../../views/adminDashboard';
import NoAuthorized from '../../views/noAuthorized';

const ProtectedRoute = ({ children }:any) => {
  const { isAuthenticated, user } = useAuth0();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: '/login' }}
      />
    );
  }

  if (isAuthenticated && !hasFootholdPermission(user)) {
    return (
      <Redirect
        to={{ pathname: '/no-authorized' }}
      />
    );
  }

  return children;
};

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    </Route>
    <Route exact path="/folder-dashboards/:id">
      <ProtectedRoute>
        <FolderDashboard />
      </ProtectedRoute>
    </Route>
    <Route exact path="/admin-dashboards">
      <ProtectedRoute>
        <AdminDashboard />
      </ProtectedRoute>
    </Route>
    <Route exact path="/no-authorized">
      <NoAuthorized />
    </Route>
    <Route>
      <LoginPage />
    </Route>
  </Switch>
);

export default Routes;
