/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Link } from 'react-router-dom';
// import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {
  // People as PeopleIcon,
  // Delete as DeleteIcon,
  ChevronLeft as ChevronLeftIcon,
  // ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

import useStyles from './style';

interface DrawerHeaderProps {
  handleDrawerOpen: () => void,
  handleDrawerClose: () => void,
  title: string,
}

// Commented code in this component is needed to finish show/hide sidebar feature

const DrawerHeader = (props: DrawerHeaderProps) => {
  const classes = useStyles();
  // const theme = useTheme();

  return (
    <div className={classes.drawerHeader}>
      <Tooltip title="Return to folders">
        <Link to="/">
          <IconButton
            className={classes.backToFolderButton}
            onClick={props.handleDrawerClose}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Link>
      </Tooltip>
      <Typography className={classes.white}>
        {props.title}
      </Typography>
      <div className={classes.iconContainer}>
        {/* <Tooltip title="Team">
          <IconButton
            className={classes.backToFolderButton}
            onClick={props.handleDrawerClose}
          >
            <PeopleIcon className={classes.white} />
          </IconButton>
        </Tooltip>
        <div className={classes.divisor} />
        <Tooltip title="Delete">
          <DeleteIcon className={classes.white} />
        </Tooltip> */}
      </div>
      {/* <div className={classes.collapseButtonContainer}>
        <Tooltip title="Collapse">
          <IconButton
            className={classes.collapseButton}
            onClick={props.handleDrawerClose}
          >
            {theme.direction === 'ltr'
              ? <ChevronLeftIcon />
              : <ChevronRightIcon />}
          </IconButton>
        </Tooltip>
      </div> */}
    </div>
  );
};

export default DrawerHeader;
