import { axios } from '../../utils/httpRequests';

export async function getFolders():Promise<FolderType[]> {
  const { data } = await axios.get('folders');
  return data;
}

export async function createFolder(name:string):Promise<FolderType> {
  const { data } = await axios.post('folders', { name });
  return data;
}

export async function deleteFolderById(id:number | null):Promise<Boolean> {
  const { status } = await axios.delete(`folders/${id}`);
  return status === 204;
}

export async function addCollaboratorToFolder(folderId:number | null, userId:string):Promise<string> {
  const { data } = await axios.post(`folders/${folderId}/collaborators`, { user_id: userId });
  return data;
}

export async function removeCollaboratorFromFolder(
  folderId:number | null,
  collaboratorId:string,
):Promise<string> {
  const { data } = await axios.delete(`folders/${folderId}/collaborators/${collaboratorId}`);
  return data;
}

export async function hasFolderBeenVisited(folderId:number | null):Promise<Boolean> {
  const { data } = await axios.get(`folders/${folderId}/has-been-visited`);
  return data === 'True';
}
