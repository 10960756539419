import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

export default makeStyles((theme: Theme) => createStyles({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: theme.palette.info.main,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.info.main,
    top: 'auto',
    overflow: 'inherit',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.main,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    position: 'relative',
  },
  white: {
    color: 'white',
  },
  iconContainer: {
    display: 'flex',
  },
  divisor: {
    margin: theme.spacing(0.5),
  },
  backToFolderButton: {
    color: 'white',
    padding: 0,
    backgroundColor: theme.palette.primary.main,
  },
  collapseButton: {
    padding: 3,
    backgroundColor: 'white',
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  collapseButtonContainer: {
    position: 'absolute',
    left: `${drawerWidth - 15}px`,
    top: '115px',
    zIndex: 1000,
  },
  greenButton: {
    backgroundColor: theme.palette.success.main,
    color: 'white',
    margin: '10px',
    '&:hover': {
      backgroundColor: theme.palette.success.light,
    },
  },
  reportText: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.primary.main,
  },
  reportContainer: {
    margin: theme.spacing(0.8),
    marginLeft: theme.spacing(2.5),
    cursor: 'pointer',
  },
}));
