/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => (
  {
    formControl: {
      width: '100%',
    },
  }
));

interface DropdownProps {
  handleSetDocumentType: (citationId: number, newDocumentTypeId: number) => void,
  options: FootholdDocumentType[],
  value: number,
  row: Citation,
}

const Dropdown = (props: DropdownProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="dropdown-select">Document Type</InputLabel>
      <Select
        labelId="dropdown-select"
        id="dropdown"
        value={props.value}
        onChange={(event: ChangeEvent<{value: unknown}>) => {
          return props.handleSetDocumentType(props.row.citation_id, event.target.value as number);
        }}
      >
        {
          props.options.map((option: FootholdDocumentType) => (
            <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default Dropdown;
